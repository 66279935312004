<div class="firm-admin" style="height: 100%">
    <div class="content clean">
        <div class="left-panel no-float left">
            <div class="panel-content left-sidebar">
                <ul class="custom-tree">
                    <li class="list">
                        <div routerLink="/company-admin" routerLinkActive="open">
                            <i class="folder fa fa-circle"></i>
                            <span>{{ 'USER_PROFILE.COMPANY_ADMIN' | translate }}</span>
                        </div>
                        <ul class="custom-tree">
                            <li class="list" routerLink="/company-admin/firm" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.FIRMDATA' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('CHANGE_PASSWORD')" routerLink="/company-admin/profile" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.MY_PROFILE' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('CHANGE_EMAIL')" routerLink="/company-admin/email" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.MY_EMAIL' | translate }}</span>
                            </li>
                            <li class="list" routerLink="/company-admin/notifications" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.MY_NOTIFICATIONS' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('USER_MANAGEMENT')" routerLink="/company-admin/user" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.USER_MANAGEMENTS' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('SUPPLIER_MANAGEMENT')" routerLink="/company-admin/suppliers" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('FIRM-ADMIN')"
                                routerLink="/company-admin/tools" routerLinkActive="open">
                                <i class="folder fa fa-circle"></i>
                                <span>{{ 'USER_PROFILE.EXTERNAL_TOOLS' | translate }}</span>
                            </li>
                            <li class="list" *ngIf="userService.allow('SETTINGS_MANAGEMENT')" >
                                <div routerLink="/company-admin/settings" routerLinkActive="open">
                                    <i class="folder fa fa-circle"></i>
                                    <span>{{ 'USER_PROFILE.SETTINGS' | translate }}</span>
                                </div>
                                <ul class="custom-tree">
                                    <li class="list" routerLink="/company-admin/settings/wage-rate" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'USER_PANEL.HOURLY_RATE' | translate }}</span>
                                    </li>
                                    <li class="list" routerLink="/company-admin/settings/logo" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'USER_PROFILE.FIRM_LOGO' | translate }}</span>
                                        <span *ngIf="companyAdminService.hasLogoChanges">{{ 'ADMIN_PANEL.UNSAVED' | translate }}</span>
                                    </li>
                                    <li class="list" routerLink="/company-admin/settings/advanced" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'USER_PROFILE.EXTENDED_OPTIONS' | translate }}</span>
                                        <span *ngIf="companyAdminService.hasChanges">{{ 'ADMIN_PANEL.UNSAVED' | translate }}</span>
                                    </li>
                                    <li class="list" routerLink="/company-admin/settings/supplier-blacklist" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'USER_PROFILE.SUPPLIER_BLACKLIST' | translate }}</span>
                                        <span *ngIf="companyAdminService.hasChangesBlacklist">{{ 'ADMIN_PANEL.UNSAVED' | translate }}</span>
                                    </li>
                                </ul>
                            </li>
                            <li class="list"
                                *ngIf="userService.allow('ASSORTMENT_PRIORITISATION')
                                && !userService.getActiveForName('EXTENDED_SETTINGS', 'AUTOMATIC_ASSORTMENT_PRIORITIZATION')
                                && userService.getActiveForName('EXTENDED_SETTINGS','FIRM_ASSORTMENT_PRIORITIZATION')">
                                <div routerLink="/company-admin/assortment" routerLinkActive="open">
                                    <i class="folder fa fa-circle"></i>
                                    <span>{{ 'CLUB_ADMIN.ASSORTMENT' | translate }}</span>
                                </div>
                                <ul class="custom-tree">
                                    <li class="list" routerLink="/company-admin/assortment/supplier" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'ASSORTMENT.SUPPLIER' | translate }}</span>
                                    </li>
                                    <li class="list" routerLink="/company-admin/assortment/article" routerLinkActive="open">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'ASSORTMENT.ARTICLE' | translate }}</span>
                                    </li>
                                </ul>
                            </li>
                            <li class="list" *ngIf="userService.allow('ORDER_HISTORY')">
                                <div (click)="orderHistoryService.setHistory('orderhistory')" routerLink="/company-admin/orderhistory" routerLinkActive="open">
                                    <i class="folder fa fa-circle"></i>
                                    <span>{{ 'CART.ORDER_HISTORY' | translate }}</span>
                                </div>
                                <ul class="custom-tree">
                                    <li class="list"
                                        routerLink="/company-admin/orderhistory/last7" routerLinkActive="open"
                                        (click)="orderHistoryService.setHistory('last7')">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'CART.HISTORY.LAST7' | translate }}</span>
                                    </li>
                                    <li class="list"
                                        routerLink="/company-admin/orderhistory/last14" routerLinkActive="open"
                                        (click)="orderHistoryService.setHistory('last14')">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'CART.HISTORY.LAST14' | translate }}</span>
                                    </li>
                                    <li class="list"
                                        routerLink="/company-admin/orderhistory/last30"  routerLinkActive="open"
                                        (click)="orderHistoryService.setHistory('last30')">
                                        <i class="folder fa fa-circle"></i>
                                        <span>{{ 'CART.HISTORY.LAST30' | translate }}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div [@fadeSmallAnimation]="getRouteAnimation(route)" class="center-panel no-float left">
            <router-outlet #route="outlet"></router-outlet>
        </div>
        <div class="right-panel no-float left">
            <div class="panel-content right-sidebar">
                <ng-container *ngIf="location?.path()?.includes('/assortment/')">
                    <app-new-assortment></app-new-assortment>
                </ng-container>
                <ng-container *ngIf="!location?.path()?.includes('/assortment/')">
                    <app-collapsible-panel [panelTitle]="'FIRM_SETTINGS.VIN_STATISTIC.TITLE' | translate"
                                           *ngIf="!orderHistoryService.selectedCart && userService.allow('FIRM-ADMIN') && companyAdminService.firmStatistics">
                        <div class="row">
                            <div class="col-6">
                                <span>{{ 'FIRM_SETTINGS.VIN_STATISTIC.CURRENT_MONTH' | translate }}</span>
                            </div>
                            <div class="col-6">
                                <span>{{ companyAdminService.firmStatistics.countCurrentMonth }} {{ 'FIRM_SETTINGS.VIN_STATISTIC.INTERROGATE' | translate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{ 'FIRM_SETTINGS.VIN_STATISTIC.LAST_MONTH' | translate }}
                            </div>
                            <div class="col-6">
                                <span>{{ companyAdminService.firmStatistics.countLastMonth }} {{ 'FIRM_SETTINGS.VIN_STATISTIC.INTERROGATE' | translate }}</span>
                            </div>
                        </div>
                    </app-collapsible-panel>
                    <app-order-details *ngIf="orderHistoryService.selectedCart"></app-order-details>
                </ng-container>
            </div>
        </div>
    </div>
</div>
